<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-overlay :show="loading">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" enctype="multipart/form-data">
                      <b-row>
                        <b-col lg="12" sm="12">
                            <ValidationProvider name="Receiver" vid="receiver_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="3"
                                  label-for="receiver_id"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('pump_install.receiver') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  id="receiver_id"
                                  v-model="application.receiver_id"
                                  :options="receivers"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Note (En)" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="3"
                              label-for="note"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('pump_install.note')}} <span class="text-danger">*</span>
                              </template>
                              <b-textarea
                              id="note"
                              v-model="application.note"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Note (bn)" vid="note_bn">
                            <b-form-group
                              class="row"
                              label-cols-sm="3"
                              :label="$t('pump_install.note_bn')"
                              label-for="note_bn"
                              slot-scope="{ valid, errors }"
                            >
                              <b-textarea
                              id="note_bn"
                              v-model="application.note_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { authServiceBaseUrl, irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { pumpOptAppSend, currentOfficeUser } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.getUsers()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.$t('globalTrans.save'),
      receivers: [],
      application: {
        pump_opt_apps_id: this.$props.id,
        sender_id: this.$store.state.Auth.authUser.user_id,
        receiver_id: 0,
        for: 1,
        note: '',
        note_bn: ''
      },
      upload_attachment: ''
    }
  },
  methods: {
    async getUsers () {
      this.loading = true
      await RestApi.getData(authServiceBaseUrl, currentOfficeUser + '/' + this.$store.state.Auth.authUser.office_id)
      .then(response => {
        this.receivers = this.getOfficerDesignation(response.data)
      })
      this.loading = false
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpOptAppSend, this.application)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        // push notification
        // const notification = result.notification
        // this.$socket.emit('send-notification', notification)

        this.$toast.success({
          title: 'Success',
          message: 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-1')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getOfficerDesignation (data) {
      return data.map(item => {
        return Object.assign({}, item, {
          value: item.id,
          text: this.$i18n.locale === 'bn' ? item.name_bn + this.getDesignation(item.designation_id) : item.name + this.getDesignation(item.designation_id)
        })
      })
    },
    getDesignation (designationId) {
      const designation = this.$store.state.commonObj.designationList.find(desg => desg.value === designationId)
      return designation !== undefined ? (this.$i18n.locale === 'bn' ? '(' + designation.text_bn + ')' : '(' + designation.text_en + ')') : ''
    }
  }
}
</script>
